<template>
  <div
    class="surface-50 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div
      class="grid justify-content-center p-2 lg:p-0"
      style="min-width: 80%"
    >
      <div class="col-12 mt-5 xl:mt-0 text-center">
        <img
          src="/images/logo.png"
          alt="BooksFlyer logo"
          class="mb-5"
          style="height: 3rem;"
        >
      </div>
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="surface-section h-full w-full m-0 py-7 px-4"
          style="border-radius: 53px"
        >
          <div class="text-center mb-5">
            <span class="text-900 text-3xl font-medium mb-3">Password Reset</span>
          </div>

          <div class="w-full md:w-10 mx-auto">
            <div class="mb-3">
              <label
                for="email1"
                class="block text-900 text-xl font-medium mb-2"
              >Account Email</label>
              <InputText
                id="email1"
                v-model="values.email"
                type="text"
                :class="['w-full', 'mb-1', !!errors.email && 'p-invalid']"
                placeholder="Account email"
                style="padding: 1rem"
                @blur="validate('email')"
                @keypress="validate('email')"
              />
              <small v-if="!!errors.email">
                {{ errors.email }}
              </small>
            </div>

            <div class="mb-3">
              <label
                for="password1"
                class="block text-900 font-medium text-xl mb-2"
              >New Password</label>
              <Password
                id="password1"
                v-model="values.password"
                placeholder="Password"
                :toggle-mask="true"
                :class="['w-full', 'mb-1', !!errors.password && 'p-invalid']"
                input-class="w-full"
                input-style="padding:1rem"
                @blur="validate('password')"
                @keypress="validate('password')"
              />
              <small
                v-if="!!errors.password"
                class="block"
              >
                {{ errors.password }}
              </small>
            </div>
            <div class="mb-3">
              <label
                for="password2"
                class="block text-900 font-medium text-xl mb-2"
              >Repeat New Password</label>
              <Password
                id="password2"
                v-model="values.password2"
                placeholder="Repeat Password"
                :toggle-mask="true"
                :class="['w-full', 'mb-1', !!errors.password && 'p-invalid']"
                input-class="w-full"
                input-style="padding:1rem"
                @blur="validate('password2')"
                @keypress="validate('password2')"
              />
              <small
                v-if="!!errors.password2"
                class="block"
              >
                {{ errors.password2 }}
              </small>
            </div>
            <div class="mb-3">
              <label
                for="username"
                class="block text-900 text-xl font-medium mb-2"
              >Reset Code</label>
              <InputText
                id="invitationCode"
                v-model="values.resetCode"
                type="text"
                :class="['w-full', 'mb-1', !!errors.resetCode && 'p-invalid']"
                placeholder="Reset code"
                style="padding: 1rem"
                @blur="validate('resetCode')"
                @keypress="validate('resetCode')"
              />
              <small v-if="!!errors.resetCode">
                {{ errors.resetCode }}
              </small>
            </div>

            <Button
              label="Reset password"
              class="w-full p-3 text-xl"
              :disabled="loading"
              @click="resetPasswordHandler"
            />
            <p class="text-center pt-3">
              <router-link
                to="/login"
              >
                Log In
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import {
  object, string, ref,
} from 'yup';
import { mapMutations } from 'vuex';
import A2CAClient from '../api/a2caClient';

const signupSchema = object().shape({
  email: string().required().email(),
  password: string().min(4).required(),
  password2: string().oneOf([ref('password'), null], 'Passwords do not match.'),
  resetCode: string().required(),
});

const client = new A2CAClient();

export default {
  data() {
    return {
      values: {
        email: this.$route.query.email,
        password: '',
        password2: '',
        resetCode: this.$route.query.code,
      },
      errors: {
        email: '',
        password: '',
        password2: '',
        resetCode: '',
      },
      loading: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    logoColor() {
      if (this.$appState.darkTheme) return 'white';
      return 'dark';
    },
  },
  methods: {
    resetPasswordHandler() {
      signupSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.errors = {};
          this.loading = true;
          try {
            await this.fetchPerformReset(
              this.values.email,
              this.values.password,
              this.values.resetCode,
            );
            this.$router.push({ name: 'login', query: { resetPassword: 'true' } });
            /*             this.values.email = '';
            this.values.password = '';
            this.values.password2 = '';
            this.values.resetCode = ''; */
          } catch (err) {
            this.$toast.add({
              severity: 'error',
              summary: 'Reset Password Error',
              detail: 'Could not reset password. Invalid email or reset code.',
              life: 3000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
    validate(field) {
      signupSchema
        .validateAt(field, this.values, { abortEarly: true })
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[field] = err.message;
        });
    },
    async fetchPerformReset(email, password, code) {
      await client.performPasswordReset(email, code, password);
    },
    ...mapMutations(['login']),
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}

.terms-and-conditions {
  color: #6465F1;
}

.error-label {
  color: #e24c4c;;
}

</style>
